<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "SetCodeFunctions.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onDebugButtonClick="onDebugButtonClick"
  />
  <FunctionDebugModal
    ref="functionDebugModal"
    :model="functionDebug.model"
    :refresh="functionDebug.refresh"
    @onDebugButtonClick="onDebugButtonClick"
  />
</template>
<script>
import FunctionDebugModal from "@/components/custom/setcode/function/FunctionDebugModal.vue";
export default {
  name: "SetCodeFunctionList",
  components: {
    FunctionDebugModal,
  },
  data() {
    return {
      gridSettings: {
        action: "SetCodeFunctionList",
        requestUrl: String.format("/Brs-SetCodeFunctionList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "functionId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/SetCode/Function/Edit/`,
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Brs-SetCodeFunctionDelete",
        autoOrderProcess: true,

        buttons: [
          {
            name: "debug",
            cssClass: "btn-primary",
            iconClass: "bi-play",
            routeButton: false,
            emitMethodName: "onDebugButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#functionDebugModal",
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.FunctionDebugModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "SetCodeFunctionEdit",
              params: {
                functionId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BaseModelFields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "text",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      functionDebug: {
        model: null,
        refresh: null,
      },
      modelCodeList: [],
    };
  },
  methods: {
    getModelCode() {
      this.$prodGatewayAxios
        .get(`/Brs-SetCodeModelList`)
        .then((response) => {
          const result = response.data.items;
          this.modelCodeList = result;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    onDebugButtonClick(rowData) {
      this.functionDebug.model = rowData;
      this.functionDebug.refresh = String.newGuid();
      const selectedPublicId = rowData.functionInputModel.publicId;
      const matchedModel = this.modelCodeList.find(
        (model) => model.publicId === selectedPublicId
      );
      rowData.functionInputModel.modelCode = matchedModel.modelCode;
    },
  },
  mounted() {
    this.getModelCode();
  },
};
</script>
